/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "grabinfo",
            "endpoint": "https://1qm4mynn85.execute-api.us-east-2.amazonaws.com/newone",
            "region": "us-east-2"
        },
        {
            "name": "grabqbcust",
            "endpoint": "https://qqpebigmmi.execute-api.us-east-2.amazonaws.com/newone",
            "region": "us-east-2"
        },
        {
            "name": "testsend",
            "endpoint": "https://36br1cfhdd.execute-api.us-east-2.amazonaws.com/newone",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5ns5oir235elva37khjqgtaz3y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tk6gu2o64raa7d74ci6ko2y55a",
    "aws_cognito_identity_pool_id": "us-east-2:9c0ff7bc-13b0-4ddc-a05d-4d8012de296a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_20PYdOWTN",
    "aws_user_pools_web_client_id": "4kfmct8l6qdqq4ico01597jg7b",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
