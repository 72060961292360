import React from "react";

import "primeflex/primeflex.css";



const Info = ({
  selectedDough,
  setSelectedDough,
  doughComponents,
  setDoughComponents,
  setIsModified,
}) => {
  return (
    <React.Fragment>
      
    </React.Fragment>
  );
};

export default Info;
